<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
</style>
<style lang="scss" scoped>
.card-custom {
  .body {
    .fixed {
      position: fixed;
      width: 77.1rem;
      background-color: #ffffff;
      left: 23.6rem;
      z-index: 1;
      top: 15%;
      .search {
        margin-left: 2% !important;
      }
    }
    .move-below {
      margin-top: 13% !important;
    }
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<template>
  <div class="row" id="email-smtp-config">
    <div class="col-md-8 offset-2">
      <div class="card form-body">
        <div class="card-body">
          <legend><h5>Featured</h5></legend>
          <div class="col-md-12" style="background:white">
            <div
              style="
    position: sticky;
    top: 99px;
    z-index: 9;
    background: white;
"
            >
              <button
                v-if="featureds.length > 0"
                ref="kt_update_note"
                type="reset"
                class="btn btn-success mr-2"
                style="height: 36px;float: right;margin-top: 18px;"
                @click="storeUpdate()"
              >
                Update
              </button>
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th scope="col">Service Name</th>
                  <th scope="col">Type</th>
                </tr>
              </thead>
              <draggable v-model="featureds" tag="tbody">
                <tr v-for="item in featureds" :key="item.name">
                  <td>
                    <i class="la la-arrows-v" aria-hidden="true"></i>
                  </td>
                  <td>{{ item.object_detail.name }}</td>
                  <td>
                    {{ item.feature_type }}
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import { FEATUREDUPDATE, FEATURED } from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  computed: {
    ...mapGetters(["currentUser", "getBServices"])
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.dispatch(FEATURED).then(res => {
      this.featureds = res.data;
    });
  },
  components: {
    draggable
  },
  methods: {
    storeUpdate() {
      this.$store
        .dispatch(FEATUREDUPDATE, [...this.featureds])
        .then(() => {
          this.$toastr.s("Service added successfully");
          this.$store.dispatch(FEATURED).then(res => {
            this.featureds = res.services;
          });
        })
        .catch(() => {
          this.$toastr.e("oops something went wrong !");
        });
    }
  },
  data() {
    return {
      loadingData: false,
      services: [],
      group: "",
      featureds: [],
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Featured",
          route: ""
        }
      ]
    };
  }
};
</script>
